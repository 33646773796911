import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../store/context';
import api from '../api/api';
import LoadingSpinner from '../components/loading-spinner';
import { BoundedArray } from '../utils';
import { styles } from '../global/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useAuthState } from '../store/auth';
import Sidebar from '../components/sidebar';
import GraphModal from '../components/graph-modal';
import StatArbPositionsModal from '../components/stat-arb-positions-modal';
import EntryProfileModal from '../components/entry-profile-modal';
import StatArbPremDiscScenariosModal from '../components/stat-arb-prem-disc-scenarios-modal';

const Indicators = () => {
  const {user, setUser, globalState} = useContext(Context);
  const MarketdataSocket = useRef(null);
  const isMarketdataReconnecting = useRef(false);
  const isMarketdataWebSocketConnected = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [indicatorsData, setIndicatorsData] = useState({});
  const [closeOrderInfo, setCloseOrderInfo] = useState({});
  const [statArbMetadata, setStatArbMetadata] = useState({});
  const [statArbPositions, setStatArbPositions] = useState({});
  const [statArbSinglePositions, setStatArbSinglePositions] = useState({});
  const [statArbEntryProfiles, setStatArbEntryProfiles] = useState({});
  const [statArbBasketsOrdering, setStatArbBasketsOrdering] = useState({});
  const [premDiscRtd, setPremDiscRtd] = useState({});
  const histPremDiscRtd = useRef({});
  const [openGraphModal, setOpenGraphModal] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [entryProfileModalData, setEntryProfileModalData] = useState({});
  const [openStatArbPositionsModal, setOpenStatArbPositionsModal] = useState(false);
  const [openStatArbEntryProfileModal, setOpenStatArbEntryProfileModal] = useState(false);
  const [openStatArbPremDiscScenariosModal, setOpenStatArbPremDiscScenariosModal] = useState(false);
  const [statArbPositionsModalData, setStatArbPositionsModalData] = useState({});
  const [statArbPremDiscScenariosData, setStatArbPremDiscScenariosData] = useState({});
  const [closeIndicators, setCloseIndicators] = useState({});
  const isComponentMounted = useRef(true);
  const indicatorsDataRef = useRef(indicatorsData);
  const [initialIntradayDataLoaded, setInitialIntradayDataLoaded] = useState(false);

  const minTransactionValue = 1600000;

  const {
    apiKey,
    setApiKey
  } = useAuthState();

  const spreadIdPositionsDirection = (positions, spreadId) => {
    // Find the direction of positions of a spread id if any positions exists.
    for (let positionId in positions) {
      if (parseInt(positions[positionId]?.spread_id) === parseInt(spreadId)) {
        return positions[positionId]?.direction;
      }
    }
  
    // Return null if no match is found
    return null;
  }

  // Function to establish the WebSocket connection
  const connectAlgoDataWebSocket = () => {  
    const connectWebSocket = () => {
      let heartbeatInterval;
      const newSocket = new WebSocket('wss://trading.tricap.dk/api/ws/marketdata');
  
      newSocket.onopen = () => {
        isMarketdataWebSocketConnected.current = true;
        newSocket.send(JSON.stringify({
          authorization: apiKey
        }));
        console.log('Connected to messaging WebSocket');
  
        // Send a heartbeat message at regular intervals
        heartbeatInterval = setInterval(() => {
          if (isMarketdataWebSocketConnected.current) {
            newSocket.send("heartbeat");
          } else {
            clearInterval(heartbeatInterval);
          }
        }, 3000);

        // Subscribe to relevant message channels.
        const subscriptionData = {
          "action": "subscribe",
          "topics": ["indicators", "premDiscRtd"]
        }
        newSocket.send(JSON.stringify(subscriptionData));
  
        // Save marketdata connection variable.
        MarketdataSocket.current = newSocket;

        isMarketdataReconnecting.current = false;
      };
  
      newSocket.onmessage = (event) => {
        const parsedMsg = JSON.parse(event.data);
        let receivedMsg;
        if (typeof parsedMsg === 'string') {
          receivedMsg = JSON.parse(parsedMsg);
        } else {
          receivedMsg = parsedMsg
        }
        if (receivedMsg?.source?.Text === "indicators") {
          if (Object.keys(indicatorsDataRef.current)?.length === 0) {
            setIndicatorsData(receivedMsg);
          } else {
            let updatedIndicatorsData = {...indicatorsDataRef.current};
            for (let key in receivedMsg) {
              if (key !== "indicators") {
                if (updatedIndicatorsData[key]["Indicator"]?.buy_spread && receivedMsg[key]["Indicator"].buy_spread) {
                  updatedIndicatorsData[key]["Indicator"] = {
                    timestamps: [...(updatedIndicatorsData?.[key]?.["Indicator"]?.timestamps || []), ...receivedMsg?.[key]?.["Indicator"]?.timestamp],
                    buy_spread: [...updatedIndicatorsData[key]["Indicator"].buy_spread, ...receivedMsg[key]["Indicator"].buy_spread],
                    buy_z_score: receivedMsg[key]["Indicator"].buy_z_score,
                    buy_mean: receivedMsg[key]["Indicator"].buy_mean,
                    buy_std: receivedMsg[key]["Indicator"].buy_std,
                    indicator: receivedMsg[key]["Indicator"].indicator,
                    sell_spread: [...updatedIndicatorsData[key]["Indicator"].sell_spread, ...receivedMsg[key]["Indicator"].sell_spread],
                    sell_z_score: receivedMsg[key]["Indicator"].sell_z_score,
                    sell_mean: receivedMsg[key]["Indicator"].sell_mean,
                    sell_std: receivedMsg[key]["Indicator"].sell_std,
                    mid_spread: [...updatedIndicatorsData[key]["Indicator"].mid_spread, ...receivedMsg[key]["Indicator"].mid_spread],
                    mid_z_score: receivedMsg[key]["Indicator"].mid_z_score,
                    tickers: receivedMsg[key]["Indicator"].tickers
                  }
                }
              }
            }
            setIndicatorsData(updatedIndicatorsData);
          }
        } else {
          // Update the representation for most recent timestep.
          setPremDiscRtd(prevState => ({...prevState, ...receivedMsg?.data}));

          // Add most recent timestep to historical variable.
          let updatedHistPremDiscRtd = {...histPremDiscRtd.current}
          for (let ticker in receivedMsg?.data) {
            if (ticker in updatedHistPremDiscRtd) {
              updatedHistPremDiscRtd[ticker].time.push(receivedMsg?.timestamp)
              updatedHistPremDiscRtd[ticker].bid.push(receivedMsg?.data?.[ticker]?.[1])
              updatedHistPremDiscRtd[ticker].mid.push(receivedMsg?.data?.[ticker]?.[2])
              updatedHistPremDiscRtd[ticker].ask.push(receivedMsg?.data?.[ticker]?.[3])
            } else {
              updatedHistPremDiscRtd[ticker] = {};
              updatedHistPremDiscRtd[ticker].time = new BoundedArray(30000);
              updatedHistPremDiscRtd[ticker].bid = new BoundedArray(30000);
              updatedHistPremDiscRtd[ticker].mid = new BoundedArray(30000);
              updatedHistPremDiscRtd[ticker].ask = new BoundedArray(30000);
              updatedHistPremDiscRtd[ticker].time.push(receivedMsg?.timestamp)
              updatedHistPremDiscRtd[ticker].bid.push(receivedMsg?.data?.[ticker]?.[1])
              updatedHistPremDiscRtd[ticker].mid.push(receivedMsg?.data?.[ticker]?.[2])
              updatedHistPremDiscRtd[ticker].ask.push(receivedMsg?.data?.[ticker]?.[3])
            }
          }
          histPremDiscRtd.current = updatedHistPremDiscRtd;
        }
      };
  
      newSocket.onclose = () => {
        console.log('Websocket connection closed');
        isMarketdataWebSocketConnected.current = false;
        clearInterval(heartbeatInterval);

        // Check if reconnection is already in progress
        if (!isMarketdataReconnecting.current) {
          // Attempt to reconnect every 5 seconds
          setTimeout(() => {
            if (isComponentMounted.current) {
              isMarketdataReconnecting.current = true; // Set the flag

              // Reconnect to websocket.
              connectWebSocket();
            }
          }, 5000);
        }
      };
  
      return newSocket;
    };
  
    return connectWebSocket();
  };

  // Initiate marketdata and orders websocket connection.
  useEffect(() => {
    MarketdataSocket.current = connectAlgoDataWebSocket();

    return () => {
      isComponentMounted.current = false;
      if (MarketdataSocket.current) {
        MarketdataSocket.current.close();
      }
    };
  }, []);

  // Retrieve latest intraday data for all tickers to calculate historical spread for visualization.
  useEffect(() => {
    if (statArbMetadata && !initialIntradayDataLoaded) {
      setIsLoading(true);
      api("algos/latest-intraday").then(res => res.json()).then(data => {
        if (data) {
          // Save prem/disc values.
          let updatedHistPremDiscRtd = {};
          let timestamps;
          for (let ticker in data) {
            updatedHistPremDiscRtd[ticker] = {};
            updatedHistPremDiscRtd[ticker].time = new BoundedArray(30000);
            updatedHistPremDiscRtd[ticker].bid = new BoundedArray(30000);
            updatedHistPremDiscRtd[ticker].mid = new BoundedArray(30000);
            updatedHistPremDiscRtd[ticker].ask = new BoundedArray(30000);
            
            updatedHistPremDiscRtd[ticker].time.array = data?.[ticker]?.time;
            updatedHistPremDiscRtd[ticker].bid.array = data?.[ticker]?.bid_prem_disc;
            updatedHistPremDiscRtd[ticker].mid.array = data?.[ticker]?.mid_prem_disc;
            updatedHistPremDiscRtd[ticker].ask.array = data?.[ticker]?.ask_prem_disc;

            timestamps = data?.[ticker]?.time;
          }
          histPremDiscRtd.current = updatedHistPremDiscRtd;
          
          // Calculate spread values.
          let updatedIndicatorsData = {...indicatorsDataRef.current};
          for (let spreadId in statArbMetadata) {
            let buySpread = [];
            let midSpread = [];
            let sellSpread = [];
            for (let t=0; t<2000; t++) {
              let curBuySpread = 0;
              let curMidSpread = 0;
              let curSellSpread = 0;
              for (let i=0; i<statArbMetadata[spreadId]?.basket?.tickers?.length; i++) {
                // Mid
                curMidSpread += data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.length-2000+t]*statArbMetadata[spreadId]?.basket?.ratios?.[i]

                // Buy
                if (statArbMetadata[spreadId]?.basket?.ratios?.[i] > 0) {
                  curBuySpread += ((data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.length-2000+t]+data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.ask_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.ask_prem_disc?.length-2000+t])/2)*statArbMetadata[spreadId]?.basket?.ratios?.[i]
                } else {
                  curBuySpread += ((data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.length-2000+t]+data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.bid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.bid_prem_disc?.length-2000+t])/2)*statArbMetadata[spreadId]?.basket?.ratios?.[i]
                }

                // Sell
                if (statArbMetadata[spreadId]?.basket?.ratios?.[i] > 0) {
                  curSellSpread += ((data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.length-2000+t]+data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.bid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.bid_prem_disc?.length-2000+t])/2)*statArbMetadata[spreadId]?.basket?.ratios?.[i]
                } else {
                  curSellSpread += ((data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.mid_prem_disc?.length-2000+t]+data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.ask_prem_disc?.[data?.[statArbMetadata[spreadId]?.basket?.tickers?.[i]]?.ask_prem_disc?.length-2000+t])/2)*statArbMetadata[spreadId]?.basket?.ratios?.[i]
                }
              }
              buySpread.push(curBuySpread);
              midSpread.push(curMidSpread);
              sellSpread.push(curSellSpread);
            }
            if (!(spreadId in updatedIndicatorsData)) {
              updatedIndicatorsData[spreadId] = {};
            }
            updatedIndicatorsData[spreadId]["Indicator"] = {
              timestamps: timestamps?.slice(-2000),
              buy_spread: buySpread,
              buy_z_score: updatedIndicatorsData?.[spreadId]?.["Indicator"]?.buy_z_score,
              indicator: updatedIndicatorsData?.[spreadId]?.["Indicator"]?.indicator,
              sell_spread: sellSpread,
              sell_z_score: updatedIndicatorsData?.[spreadId]?.["Indicator"]?.sell_z_score,
              mid_spread: midSpread,
              mid_z_score: updatedIndicatorsData?.[spreadId]?.["Indicator"]?.mid_z_score,
              tickers: updatedIndicatorsData?.[spreadId]?.["Indicator"]?.tickers
            }
          }
          // In memory holder for indicators data updated real-time.
          setIndicatorsData(updatedIndicatorsData);

          setIsLoading(false);
          setInitialIntradayDataLoaded(true);
        }
      });
    }    
  }, [statArbMetadata])

  // Retrieve Stat Arb metadata.
  useEffect(() => {
    api("algos/stat-arb-metadata").then(res => res.json()).then(data => {
      if (data) {
        setStatArbMetadata(data);
      }
    });    
  }, [statArbPositions])

  // Retrieve Stat Arb positions.
  useEffect(() => {
    api("algos/stat-arb-positions").then(res => res.json()).then(data => {
      if (data) {
        setStatArbPositions(data);
      }
    });    
  }, []);

  // Retrieve Stat Arb single positions.
  useEffect(() => {
    api("algos/stat-arb-single-positions").then(res => res.json()).then(data => {
      if (data) {
        setStatArbSinglePositions(data);
      }
    });    
  }, []);

  // Retrieve Stat Arb entry profiles.
  useEffect(() => {
    api("algos/stat-arb-entry-profiles").then(res => res.json()).then(data => {
      if (data) {
        setStatArbEntryProfiles(data);
      }
    });    
  }, []);

  // Retrieve Stat Arb ordering.
  useEffect(() => {
    api("algos/stat-arb-baskets-ordering").then(res => res.json()).then(data => {
      if (data) {
        setStatArbBasketsOrdering(data);
      }
    });    
  }, []);

  useEffect(() => {
    indicatorsDataRef.current = indicatorsData;
  }, [indicatorsData])

  // Construct close indicators.
  useEffect(() => {
    let curCloseIndicators = {};
    for (let key in statArbPositions) {
      if (!(statArbPositions?.[key]?.spread_id in curCloseIndicators)) {
        curCloseIndicators[statArbPositions?.[key]?.spread_id] = {
          'indicator': null
        }
      }
      if (statArbPositions?.[key]?.direction?.toLowerCase() === "buy") {
        if (indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.sell_spread[indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.sell_spread?.length-1]-statArbPositions?.[key]?.open_spread >= statArbMetadata?.[statArbPositions?.[key]?.spread_id]?.params?.take_profit) {
          curCloseIndicators[statArbPositions?.[key]?.spread_id]['indicator'] = "SELL";
          for (let i=0; i<statArbSinglePositions?.[key]?.length; i++) {
            if (statArbSinglePositions?.[key]?.[i]?.ticker in curCloseIndicators[statArbPositions?.[key]?.spread_id]) {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] += parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            } else {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] = parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            }
          }

        }
      } else if (statArbPositions?.[key]?.direction?.toLowerCase() === "sell") {
        if (statArbPositions?.[key]?.open_spread-indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.buy_spread[indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.buy_spread?.length-1] >= statArbMetadata?.[statArbPositions?.[key]?.spread_id]?.params?.take_profit) {
          curCloseIndicators[statArbPositions?.[key]?.spread_id]['indicator'] = "BUY";
          for (let i=0; i<statArbSinglePositions?.[key]?.length; i++) {
            if (statArbSinglePositions?.[key]?.[i]?.ticker in curCloseIndicators[statArbPositions?.[key]?.spread_id]) {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] += parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            } else {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] = parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            }
          }
        }
      }
    }
    setCloseIndicators(curCloseIndicators);
  }, [statArbMetadata, statArbPositions, indicatorsData]);

  useEffect(() => {
    if (openStatArbPositionsModal) {
      setStatArbPositionsModalData(prevState => ({
        tickers: prevState?.tickers,
        spread_id: prevState?.spread_id,
        positions: Object.fromEntries(
          Object.entries(statArbPositions).filter(([_, innerObject]) => innerObject["spread_id"] === parseInt(prevState?.spread_id))
        )
      }))
    }
  }, [statArbPositions])

  // Implement a ping mechanism to keep the connection alive during idle periods
  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (document.visibilityState === 'visible' && !isMarketdataWebSocketConnected.current) {
        connectAlgoDataWebSocket();
      }
    }, 30000); // Check every 30 seconds

    return () => clearInterval(pingInterval);
  }, []);
    
  return (
    <main style={{
      display: 'flex',
      height: 'calc(100vh - 40px)',
      margin: 0,
      padding: 0,
      overflow: 'hidden'
    }}>
      {isLoading && (
        <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
            <button className='hover-update' style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
              setIsLoading(false);
            }}>Cancel</button>
        </div>)}

      {/* Graph Modal */}
      {openGraphModal && (
        <GraphModal timestamps={graphData?.timestamps} mid_spread={graphData?.mid_spread} buy_spread={graphData?.buy_spread} sell_spread={graphData?.sell_spread} tickers={graphData?.tickers} title={graphData?.title} xLabel={graphData?.xLabel} yLabel={graphData?.yLabel} histPremDiscRtd={histPremDiscRtd.current} setOpenGraphModal={setOpenGraphModal}/>
      )}

      {/* Stat Arb Positions Modal */}
      {openStatArbPositionsModal && (
        <StatArbPositionsModal spreadId={statArbPositionsModalData?.spread_id} indicatorsData={indicatorsData} statArbPositions={statArbPositionsModalData?.positions} statArbMetadata={statArbMetadata} allStatArbPositions={statArbPositions} allStatArbSinglePositions={statArbSinglePositions} setStatArbPositions={setStatArbPositions} setStatArbSinglePositions={setStatArbSinglePositions} tickers={statArbPositionsModalData?.tickers} setOpenStatArbPositionsModal={setOpenStatArbPositionsModal} curIndicatorsData={statArbPremDiscScenariosData?.curIndicatorsData} minTransactionValue={minTransactionValue} premDiscRtd={premDiscRtd} openStatArbPremDiscScenariosModal={openStatArbPremDiscScenariosModal} setOpenStatArbPremDiscScenariosModal={setOpenStatArbPremDiscScenariosModal}/>
      )}

      {/* Entry Profile Modal */}
      {openStatArbEntryProfileModal && (
        <EntryProfileModal spreadId={entryProfileModalData?.spread_id} tickers={entryProfileModalData?.tickers} statArbEntryProfiles={statArbEntryProfiles} setOpenStatArbEntryProfileModal={setOpenStatArbEntryProfileModal}/>
      )}

      {/* Stat Arb Prem/Disc Scenarios Modal */}
      {(openStatArbPremDiscScenariosModal && !openStatArbPositionsModal) && (
        <StatArbPremDiscScenariosModal type={"open"} spreadId={statArbPremDiscScenariosData?.spreadId} curIndicatorsData={statArbPremDiscScenariosData?.curIndicatorsData} statArbPositions={statArbPositions} statArbMetadata={statArbMetadata} minTransactionValue={minTransactionValue} premDiscRtd={premDiscRtd} setOpenStatArbPremDiscScenariosModal={setOpenStatArbPremDiscScenariosModal}/>
      )}

      <div style={{
        flex: 1,
        overflow: 'hidden',
        border: '4px solid #ccc',
        background: '#303030',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        overflow: 'auto'
      }}>
        <Tabs style={{marginTop: 30}}>
          <TabList>
            <Tab>Statistical Arbitrage</Tab>
          </TabList>
          <TabPanel>
            <button className="hover-update" style={{ marginTop: 5, fontSize: 12, display: "flex", justifyContent: "center", alignItems: 'center'}} onClick={() => {
              // Get stat arb spread positions.
              api("algos/stat-arb-positions").then(res => res.json()).then(data => {
                if (data) {
                  setStatArbPositions(data);
                }
              });

              // Get stat arb spread individual ticker positions.
              api("algos/stat-arb-single-positions").then(res => res.json()).then(data => {
                if (data) {
                  setStatArbSinglePositions(data);
                }
              });
            }}>Import Positions</button>
            <hr style={{color: "white", marginBottom: 10, borderWidth: 1, marginTop: 10}}/>
            <table style={{
              fontSize: 10,
              whiteSpace: 'nowrap',
              width: 1400,
            }}>
              <thead style={styles.table.header}>
                <tr style={{fontSize: 14}}>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Tickers</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Z-Score (Buy/Sell)</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Indicator</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Open/Close</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Spread (Buy/Sell)</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Lookback Period (Minutes)</th>
                   <th style={{
                    border: 0,
                    padding: 10
                  }}>Margin</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Entry Z-Score</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Positions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(indicatorsData || {}).length > 0 &&
                  Object.entries(indicatorsData)?.sort(([keyA], [keyB])  => {
                      if ((statArbBasketsOrdering[keyA] || 999999) > (statArbBasketsOrdering[keyB] || 999999)) {
                        return 1;
                      } else if ((statArbBasketsOrdering[keyA] || 999999) < (statArbBasketsOrdering[keyB] || 999999)) {
                        return -1;
                      }

                      return 0;
                    })?.map(([key, value]) => {
                    const rows = [];
                    if (key !== "source") {
                      rows.push(
                      <tr key={key} className="hover:bg-gray-700" style={{fontSize: 10}}>
                        {/* Tickers */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell, 
                            textAlign: "center", 
                            padding: 2 
                          }}
                        >
                          <span className='hover-update' style={{marginTop: 3, float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 10, height: 12, backgroundColor: '#226699', cursor: 'pointer', borderRadius: 0}} onClick={e => {
                            e.preventDefault()
                            setCloseOrderInfo(prevState => ({...prevState, [key]: !closeOrderInfo?.[key]}))
                            }}>{!closeOrderInfo?.[key] ? '+' : '-'}</span>
                            {
                              value?.Indicator?.tickers?.map((ticker, i) => {
                                return (i === 0) ? (
                                  <span style={{
                                    color: 
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "green"
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "red"
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "red"
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "green" : ""
                                      :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "green"
                                        : parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "red"
                                        : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "red"
                                        : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "green"
                                        : statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ? "#6495ED" : "#f68b1f",
                                  }}>{ticker}</span>
                                 ) : (
                                  <span>
                                    <span>/</span>
                                    <span style={{
                                      color: 
                                        closeIndicators?.[key]?.indicator ? 
                                          closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                          ? "green"
                                          : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                          ? "red"
                                          : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                          ? "red"
                                          : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                          ? "green" : ""
                                        :
                                          parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                          ? "green"
                                          : parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                          ? "red"
                                          : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                          ? "red"
                                          : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                          ? "green"
                                          : statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ? "#6495ED" : "#f68b1f",
                                    }}>{ticker}</span>
                                  </span>
                                 )
                              })
                            }
                        </td>
                        {/* Z-Score */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 2 }}
                        >
                          {parseFloat(value?.Indicator?.buy_z_score)?.toFixed(2)}/{parseFloat(value?.Indicator?.sell_z_score)?.toFixed(2)}
                        </td>
                        {/* Indicator */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            backgroundColor:
                            closeIndicators?.[key]?.indicator ? 
                              closeIndicators?.[key]?.indicator === "BUY"
                                ? "green"
                                : "red"
                              :
                                parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold ? "green" :
                                parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold ? "red"
                                : "yellow",
                            color: "black",
                            padding: 2
                          }}
                        >
                          {
                            closeIndicators?.[key]?.indicator ? closeIndicators?.[key]?.indicator :
                            parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold ? "BUY" :
                            parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold ? "SELL" :
                            "HOLD"
                          }
                        </td>
                        {/* Open/Close */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell, 
                            textAlign: "center", 
                            padding: 2,
                            color:
                              parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && spreadIdPositionsDirection(statArbPositions, key) === "sell" ? "red" :
                              parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && spreadIdPositionsDirection(statArbPositions, key) === "buy" ? "red" :
                              ""
                          }}
                        >
                          {
                            closeIndicators?.[key]?.indicator ? "Close" :
                            parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && spreadIdPositionsDirection(statArbPositions, key) === "sell" ? "Close" : 
                            parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && spreadIdPositionsDirection(statArbPositions, key) === "buy" ? "Close" : 
                            "Open"
                          }
                        </td>
                        {/* Spread (Buy/Sell) */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 2
                          }}
                        >
                          <span className="underline" style={{color: "#6495ED", cursor: "pointer"}} onClick={() => {
                            setGraphData({
                              timestamps: value?.Indicator.timestamps,
                              mid_spread: value?.Indicator?.mid_spread?.map(e => parseFloat((e*100)?.toFixed(2))),
                              buy_spread: value?.Indicator?.buy_spread?.map(e => parseFloat((e*100)?.toFixed(2))),
                              sell_spread: value?.Indicator?.sell_spread?.map(e => parseFloat((e*100)?.toFixed(2))),
                              tickers: value?.Indicator?.tickers,
                              title: "Spread",
                              xLabel: "Timestep",
                              yLabel: "Spread (%)"
                            })
                            setOpenGraphModal(true);
                          }}>{`${parseFloat(value?.Indicator?.buy_spread[value?.Indicator?.buy_spread?.length - 1]*100).toFixed(2)}%`}/{`${parseFloat(value?.Indicator?.sell_spread[value?.Indicator?.sell_spread?.length - 1]*100).toFixed(2)}%`}</span>
                        </td>
                        {/* Lookback Period */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 2
                          }}
                        >{statArbMetadata?.[key]?.params?.lookback}</td>
                        {/* Margin */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 2
                          }}
                        >{`${parseFloat(statArbMetadata?.[key]?.params?.take_profit*100).toFixed(2)}%`}</td>
                        {/* Entry threshold */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 2
                          }}
                        >
                          <span className="underline" style={{color: "#6495ED", cursor: "pointer"}} onClick={() => {
                            setEntryProfileModalData({
                              'spread_id': key,
                              'tickers': value?.Indicator?.tickers
                            })
                            setOpenStatArbEntryProfileModal(true);
                          }}>{statArbMetadata?.[key]?.params?.entry_threshold?.toFixed(2)}</span>
                          </td>
                        {/* Positions */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 2
                          }}
                        >
                          <span className="underline" style={{
                            color: 
                              spreadIdPositionsDirection(statArbPositions, key) === "buy" ? "green" :
                              spreadIdPositionsDirection(statArbPositions, key) === "sell" ? "red" :
                              "#6495ED", 
                            cursor: "pointer"
                          }} onClick={() => {
                            setStatArbPositionsModalData({
                              tickers: value?.Indicator?.tickers,
                              spread_id: key,
                              positions: Object.fromEntries(
                                Object.entries(statArbPositions).filter(([_, innerObject]) => innerObject["spread_id"] === parseInt(key))
                              )
                            })
                            setStatArbPremDiscScenariosData({
                              curIndicatorsData: value
                            })
                            setOpenStatArbPositionsModal(true);
                          }}>View</span>
                        </td>
                      </tr>)
                      if (closeOrderInfo?.[key]) {
                        rows.push(
                          <tr style={{ height: 25, fontSize: 10 }}>
                            <td colSpan={9} style={{ textAlign: 'center' }}>
                              <table className="border border-solid border-gray-300/[.45]" style={{ ...styles.table.table, margin: '0 auto', border: '', padding: 0, width: 600 }}>
                                <thead style={styles.table.header}>
                                  <tr style={{fontSize: 10}}>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Ticker</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Direction</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Prem/Disc</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Price</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Amount</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Ratio</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {value?.Indicator?.tickers.map((ticker, index) => (
                                  <tr className="hover:bg-gray-700" key={index}>
                                    {/* Ticker */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "center",
                                        marginRight: 5,
                                        padding: 2
                                      }}
                                    >
                                      {ticker}
                                    </td>
                                    {/* Direction */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "center",
                                        backgroundColor:
                                          closeIndicators?.[key]?.indicator ? 
                                            closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "green"
                                            : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "red"
                                            : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "red"
                                            : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "green" : ""
                                          :
                                            parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "green"
                                            : parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "red"
                                            : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "red"
                                            : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "green"
                                            : "yellow",
                                        color: "black",
                                        padding: 2
                                      }}
                                    >{
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "BUY"
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "SELL"
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "SELL"
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "BUY" : "HOLD"
                                      :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        "BUY" :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        "SELL" :
                                        parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        "SELL"
                                        : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        "BUY" : "HOLD"
                                    }</td>
                                    {/* Prem/Disc */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 2
                                      }}
                                    >{
                                      // Identify if we want to use 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%`
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%`
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%`
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%` : "HOLD"
                                      :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%` :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%` :
                                        parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%`
                                        : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%` : ""
                                    }</td>
                                    {/* Price */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 2
                                      }}
                                    >{
                                      // Calculate price using 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) : ""
                                      :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) :
                                        parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) : ""
                                    }</td>
                                    {/* Amount */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 2
                                      }}
                                    >{
                                        closeIndicators?.[key]?.indicator ?
                                        Math.abs(closeIndicators?.[key]?.[ticker])
                                        :
                                        // Calculate amount based on price using 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() :
                                        parseFloat(value?.Indicator?.buy_z_score) < -statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() :
                                        parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString()
                                        : parseFloat(value?.Indicator?.sell_z_score) > statArbMetadata?.[key]?.params?.entry_threshold && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() : ""
                                      }</td>
                                    {/* Ratio */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 2
                                      }}
                                    >{parseFloat(statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]).toFixed(2)}</td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                              <button className='hover-update' style={{cursor: "pointer", color: "white", marginTop: 10, marginBottom: 10}} onClick={() => {
                                setStatArbPremDiscScenariosData({
                                  spreadId: key,
                                  curIndicatorsData: value
                                })
                                setOpenStatArbPremDiscScenariosModal(true);
                              }}>
                                Prem/Disc Calculation
                              </button>
                            </td>
                          </tr>
                        )
                      }
                    }
                    return rows;
                  })}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </main>
  );
}

export default Indicators;